import React, {createRef, useEffect, useState} from 'react';

import './../assets/css/font.css';
import './Ipad.css';

import {createStageClass, Sprite, AppContext} from "react-pixi-fiber";
import Card from "./Ipad/Card";
import Background from "./Ipad/Background";
import Hotspot from "./Ipad/Hotspot";
import LaunchButton from "./Ipad/LaunchButton";
import * as PIXI from "pixi.js";

import deckOfCards from "./../assets/images/deck-of-cards.png";
import {getRandomCardOptions, setSize} from "./Utils";

function Ipad() {
    let appContext
    const [scaleX, setScaleX] = useState(1)
    const [scaleY, setScaleY] = useState(1)
    const [activeCard, setActiveCard] = useState(0);
    const [cards] = useState(getRandomCardOptions())
    let launchButton = null;

    const Stage = createStageClass();

    const hotspots = [
        { x: 683, y: 209, r: 0, ref: createRef() },
        { x: 1006, y: 350, r: 0.78, ref: createRef() },
        { x: 1000, y: 691, r: -0.78, ref: createRef() },
        { x: 682, y: 835, r: 0, ref: createRef() },
        { x: 360, y: 691, r: 0.778, ref: createRef() },
        { x: 360, y: 350, r: -0.78, ref: createRef() },
        { x: 680, y: 520, r: 0, ref: createRef() }
    ];

    useEffect(() => {
        setSize(appContext, setScaleX, setScaleY);
        window.addEventListener('resize', () => {
            setSize();
        })
    })

    const onTarget = (active, targetHotspot) => {
        launchButton.setIsActive(active);
    }

    function registerLaunchButton(button) {
        launchButton = button;
    }

    function onLaunch() {
        setActiveCard(activeCard + 1);
    }

    function storeContext(app) {
        appContext = app;
        return
    }

    return (
        <div className="bg-green step">
            <Stage options={{ backgroundAlpha: 0, width: 1366, height: 1024 }} scale={[scaleX, scaleY]}>
                <AppContext.Consumer>
                    {(app) => <>{storeContext(app)}</>}
                </AppContext.Consumer>
                <Background width={1366} height={1024} />
                {hotspots.map((elem, index) =>
                    <Hotspot key={index} x={elem.x} y={elem.y} rotation={elem.r} ref={elem.ref} />
                )}

                <Sprite texture={PIXI.Texture.from(deckOfCards)}
                        anchor={0.5}
                        x={1200}
                        y={125}
                />

                <LaunchButton x={1240} y={530} onRegister={registerLaunchButton} onLaunch={onLaunch} />

                {cards.map((elem, index) => {
                        if (index === activeCard)
                            return <Card key={index} id={index} hotspots={hotspots} onTarget={onTarget} text={elem}/>
                        else
                            return null
                    }
                )}

            </Stage>
        </div>
    );
}

export default Ipad;
