import { Sprite, Text, Container } from "react-pixi-fiber";
import * as Animated from "animated";
import Card from "./../Touchpad/Card";

const ReactPixiFiberAnimated = {
    ...Animated,
    TouchpadCard: Animated.createAnimatedComponent(Card),
    Sprite: Animated.createAnimatedComponent(Sprite),
    Text: Animated.createAnimatedComponent(Text),
    Container: Animated.createAnimatedComponent(Container)
};

export default ReactPixiFiberAnimated;
