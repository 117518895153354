import {Link, useParams} from "react-router-dom";
import './Home.css';
import logoBig from './../assets/images/logo-big.png'
import btnStart from './../assets/images/btn-start.png'

function HomeGame() {
    const {version} = useParams();
    let intVersion = parseInt(version, 10);

    if (isNaN(intVersion)) {
        intVersion = 1;
    }

    return (
        <div className="Home">

            <div className="container">
                <div className="logo">
                <img src={logoBig} className="logo-big" alt={"logo"} />
                </div>
                <div className="controls">
                    <Link to={`/step/${intVersion}/1`}>
                        <img src={btnStart} className="btn-start" alt={"start"}/>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default HomeGame;
