import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Home from './components/Home';
import Ipad from './components/Ipad';
import { FontObserver } from 'react-with-async-fonts';
import Presspad from "./components/Presspad";
import DigibordPresspad from "./components/DigibordPresspad";
import Step from "./components/Step";
import Play from "./components/Play";
import PlayIpad from "./components/PlayIpad";
import HomeGame from "./components/HomeGame";

function App() {
  return (
      <FontObserver alphaKind="Alphakind Regular">
      <Router>
        <div className="App">
          <Switch>
              <Route path="/play/:version">
                  <Play />
              </Route>
              <Route path="/playipad">
                  <PlayIpad />
              </Route>
            <Route path="/step/:version/:id">
                <Step />
            </Route>
              <Route path="/ipad">
                <Ipad />
              </Route>
              <Route path="/presspad/:boardId" >
                  <Presspad />
              </Route>
              <Route path="/presspad">
                  <Presspad />
              </Route>
              <Route path="/digibord">
                  <DigibordPresspad />
              </Route>
              <Route path="/game/:version">
                  <HomeGame />
              </Route>
            <Route path="/">
                <Home />
            </Route>
          </Switch>
        </div>
      </Router>
      </FontObserver>
  );
}

export default App;
