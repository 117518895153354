import {Sprite, Container, Text} from "react-pixi-fiber";
import * as PIXI from "pixi.js";
import {forwardRef} from "react";
import deckOfCards from "../../assets/images/deck-of-cards.png";
import Animated from "./Animated";

const Card = forwardRef(({x, y, image, ...props}, ref) => {
    return <Container>
        <Sprite texture={PIXI.Texture.from(deckOfCards)}
                anchor={0.5}
                scale={1.3}
                x={x}
                y={y}
        />

        <Animated.Container
            anchor={0.5}
            x={x}
            y={y}
            ref={ref}>

            <Sprite texture={PIXI.Texture.from(image)}
                    anchor={0.5}
            />
            <Text text={props.text?.toUpperCase()}
                  anchor={0.5}
                  style={{
                      align: "center",
                      wordWrap: true,
                      wordWrapWidth: 300,
                      fontFamily: 'Alphakind Regular',
                      fill: 0xFFFFFF,
                      fontSize: '3.8em',
                      dropShadow: true,
                      dropShadowAlpha: 0.8,
                      dropShadowBlur: 5,
                      dropShadowDistance: 3
                  }}/>
        </Animated.Container>
    </Container>;
});

export default Card;
