import {Sprite} from "react-pixi-fiber";
import * as PIXI from "pixi.js";
import background from "../../assets/images/overlay-speelbord.png";


function Background({width, height, props}) {
    return <Sprite texture={PIXI.Texture.from(background)} width={width} height={height} {...props}
    />;
}

export default Background;
