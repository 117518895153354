import React from 'react';

import './../assets/css/font.css';
import './Step1.css';
import logoBig from "../assets/images/logo-big.png";

function PlayIpad() {
    return (
        <div className="Home">
            <div className="container">
                <div className="logo">
                    <img src={logoBig} className="logo-big" alt={"logo"} />
                </div>
            </div>
        </div>
    );
}

export default PlayIpad;
