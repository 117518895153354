import {Link} from "react-router-dom";
import './Home.css';
import logoBig from './../assets/images/logo-big.png'
import btnIpad from './../assets/images/button-ipad.png'
import btnDigibord from './../assets/images/button-digibord.png'

function Home() {
    return (
        <div className="Home">

            <div className="container">
                <div className="logo">
                <img src={logoBig} className="logo-big" alt={"logo"} />
                </div>
                <div className="controls">
                    <Link to={`/game/1`}>
                        <img src={btnIpad} className="btn-start" alt={"start"}/>
                    </Link>
                    <Link to={`/game/2`}>
                        <img src={btnDigibord} className="btn-start" alt={"start"}/>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Home;
