import React, {Component, createRef, useEffect} from 'react';

import './../assets/css/font.css';
import './Ipad.css';

import {Container, createStageClass, Sprite, Text} from "react-pixi-fiber";
import * as PIXI from "pixi.js";
import Animated from "./Touchpad/Animated";

import background from "./../assets/images/background-digibord.png"

import cardOrange from "./../assets/images/card-orange.png";
import cardGreen from "./../assets/images/card-green.png";
import cardPurple from "./../assets/images/card-purple.png";
import cardPink from "./../assets/images/card-pink.png";
import cardRed from "./../assets/images/card-red.png";
import cardTurquoise from "./../assets/images/card-turquoise.png";
import cardYellow from "./../assets/images/card-yellow.png";
import cardBrown from "./../assets/images/card-brown.png";
import {getRandomCardOptionsDigibord} from "./Utils";
import Hotspot from "./Ipad/Hotspot";
import deckOfCards from "../assets/images/deck-of-cards.png";
import {createSocket} from "./Socket";

class AnimatedCard extends Component {
    state = {
        x: new Animated.Value(0),
        y: new Animated.Value(0),
        opacity: new Animated.Value(1),
        index: 0
    }

    constructor(props) {
        super(props);

        if (props.registerClass) {
            props.registerClass(this);
        }
    }

    componentDidMount() {
        this.reset();
    }

    reset(index) {
        this.setState({
            index: index ? index : 0,
            x: new Animated.Value(this.props.x),
            y: new Animated.Value(this.props.y),
            opacity: new Animated.Value(1)
        })
    }

    setPos(x, y) {
        Animated.timing(this.state.opacity, { toValue: 0, duration: 300, delay: 600 }).start(() => {
            if (this.state.index + 1 >= this.props.options.length) {
                // Nothing to do
            } else {
                this.reset(this.state.index + 1);
            }
        })
        Animated.spring(this.state.x, { toValue: x }).start();
        Animated.spring(this.state.y, { toValue: y }).start();
    }

    render() {
        return <Container>
            <Sprite texture={PIXI.Texture.from(deckOfCards)}
                    anchor={0.5}
                    scale={1.3}
                    x={this.props.x}
                    y={this.props.y}
            />

            <Animated.Container
                anchor={0.5}
                x={this.state.x}
                y={this.state.y}
                alpha={this.state.opacity}
                >

                <Sprite texture={PIXI.Texture.from(this.props.image)}
                        anchor={0.5}
                />

                <Text text={"WIE BEPAALT"}
                      anchor={0.5}
                      y={-70}
                      style={{
                          align: "center",
                          wordWrap: true,
                          wordWrapWidth: 300,
                          fontFamily: 'Alphakind Regular',
                          fill: 0xFFFFFF,
                          fontSize: '2.5em',
                          dropShadow: true,
                          dropShadowAlpha: 0.8,
                          dropShadowBlur: 5,
                          dropShadowDistance: 3
                      }}/>
                <Text text={this.props.options[this.state.index].toUpperCase()}
                      anchor={0.5}
                      style={{
                          align: "center",
                          wordWrap: true,
                          wordWrapWidth: 300,
                          fontFamily: 'Alphakind Regular',
                          fill: 0xFFFFFF,
                          fontSize: '3.8em',
                          dropShadow: true,
                          dropShadowAlpha: 0.8,
                          dropShadowBlur: 5,
                          dropShadowDistance: 3
                      }}/>
            </Animated.Container>
        </Container>
    }
}

function DigibordPresspad() {

    const hotspots = [
        { x: 960, y: 150, r: 0, ref: createRef() },
        { x: 1006 + 323, y: 346, r: 0, ref: createRef() },
        { x: 1000 + 331, y: 710, r: 0, ref: createRef() },
        { x: 960, y: 595, r: 0, ref: createRef() },
        { x: 960, y: 880, r: 0, ref: createRef() },
        { x: 360 + 230, y: 702, r: 0, ref: createRef() },
        { x: 360 + 230, y: 345, r: 0, ref: createRef() },
    ];

    //{x: 220, y: 150, asset: cardOrange},
    const cards = [
        {x: 220, y: 150, asset: cardOrange, options: getRandomCardOptionsDigibord(), ref: createRef()},
        {x: 220, y: 400, asset: cardGreen, options: getRandomCardOptionsDigibord(), ref: createRef()},
        {x: 220, y: 650, asset: cardPurple, options: getRandomCardOptionsDigibord(), ref: createRef()},
        {x: 220, y: 900, asset: cardPink, options: getRandomCardOptionsDigibord(), ref: createRef()},

        {x: 1700, y: 150, asset: cardRed, options: getRandomCardOptionsDigibord(), ref: createRef()},
        {x: 1700, y: 400, asset: cardTurquoise, options: getRandomCardOptionsDigibord(), ref: createRef()},
        {x: 1700, y: 650, asset: cardYellow, options: getRandomCardOptionsDigibord(), ref: createRef()},
        {x: 1700, y: 900, asset: cardBrown, options: getRandomCardOptionsDigibord(), ref: createRef()},
    ];

    const Stage = createStageClass();

    useEffect(() => {
        setSize();
        window.addEventListener('resize', () => {
            setSize();
        })

        const socket = createSocket()
        socket.on('error', (e) => {
            if (console && console.log) {
                console.log('error', e)
            }
        })
        socket.on('press', (data) => {
            if (typeof data.id !== 'undefined' && typeof data.board !== 'undefined'
                && typeof cards[data.board] !== 'undefined' && typeof hotspots[data.id] !== 'undefined') {
                pickCard(data.board, data.id)
            }
        })
    })

    function setSize() {
        // setScale(window.innerWidth / initialWidth);
    }

    function pickCard(cardId, hotspotId) {
        const hotspot = hotspots[hotspotId];
        const card = cards[cardId];

        if (card.classRef) {
            card.classRef.setPos(hotspot.x, hotspot.y);
        }
    }

    return (
        <div className="bg-black step">
            <Stage options={{width: 1920, height:1080}}>
                <Sprite texture={PIXI.Texture.from(background)} />

                {hotspots.map((elem, index) =>
                    <Hotspot key={index} x={elem.x} y={elem.y} rotation={elem.r} ref={elem.ref} />
                )}

                {cards.map((card, index) => {
                    return <AnimatedCard hotspots={hotspots}
                                  key={index}
                                  x={card.x}
                                  y={card.y}
                                  image={card.asset} options={card.options}
                                  registerClass={(classRef) => card.classRef = classRef}/>
                })}
            </Stage>
        </div>
    );
}

export default DigibordPresspad;
