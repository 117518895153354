import _ from "lodash";

export function hitTestRectangle(a, b)
{
    var ab = a.getBounds();
    var bb = b.getBounds();

    return ab.x + ab.width > bb.x && ab.x < bb.x + bb.width && ab.y + ab.height > bb.y && ab.y < bb.y + bb.height;
}


const initialWidth = 1366;
const initialHeight = 1024;

export function setSize(appContext, setScaleX, setScaleY) {
    if (appContext) {
        appContext.renderer.resize( window.innerWidth, window.innerHeight);
        setScaleX(window.innerWidth / initialWidth)
        setScaleY(window.innerHeight / initialHeight)
    }
}

export const cardOptions = [
    "Falen",
    "Leren",
    "Genieten",
    "Haast hebben",
    "Voor de gek houden",
    "Ondankbaar zijn",
    "Om anderen geven",
    "Fantaseren",
    "Instinct hebben",
    "Veranderen",
    "Eten",
    "Eenzaam voelen",
    "Iets uitvinden",
    "Behulpzaam zijn",
    "Denken",
    "Doodgaan",
    "Dromen",
    "Geboren worden",
    "Gekieteld worden",
    "Klokkijken",
    "Kleuren ervaren",
    "Lief zijn"
]


export const cardOptionsDigibord = [
    "Welke game je speelt",
    "Je bijnaam",
    "Wat je eet",
    "Met wie je speelt",
    "Wat je denkt",
    "Welke kleren je draagt",
    "Wat je later wordt",
    "Waar je je geld aan uitgeeft",
    "Wat je op school doet",
    "Of je ziek bent",
    "Waar je goed in bent",
    "Hoe je je haar doet",
    "Hoe laat je uit bed gaat",
    "Wat je leuk vindt",
    "Wat echt is en nep is",
    "Met welke hand je schrijft",
    "Wat je voelt",
    "Wat mooi is",
    "Wat goed voor jou is"
]
export function getRandomCardOptions() {
    return _.shuffle(cardOptions.slice())
}
export function getRandomCardOptionsDigibord() {
    return _.shuffle(cardOptionsDigibord.slice())
}
