import { CustomPIXIComponent } from "react-pixi-fiber";
import * as PIXI from "pixi.js";

const TYPE = "Rectangle";
export const behavior = {
    customDisplayObject: props => new PIXI.Container(),
    customApplyProps: function(instance, oldProps, newProps) {
        const { fill, fillOpacity, x, y, width, height, rotation } = newProps;
        const rect = new PIXI.Graphics();
        rect.x = x;
        rect.y = y;
        rect.clear();
        rect.beginFill(fill, fillOpacity);
        rect.drawRoundedRect(0 - (width / 2), 0 - (height / 2), width, height, 16);
        rect.endFill();
        instance.addChild(rect)
        rect.rotation = rotation;
    }
};
export default CustomPIXIComponent(behavior, TYPE);
