import {Link, useParams} from "react-router-dom";
import React from 'react';

import './../assets/css/font.css';
import './Step1.css';

import playInstructions from "../assets/images/play-instructions.png";
import btnLaunch from "../assets/images/button-launch.png";

function Play(props) {
    const {version} = useParams();
    const intVersion = parseInt(version, 10) - 1;

    const launchUrl = intVersion === 1 ? '/digibord' : '/playipad'

    return (

        <div className="Home">

            <div className="container">
                <div className="logo">
                    <img src={playInstructions} className="logo-big" alt={"logo"} />
                </div>
                <div className="controls">
                    <Link to={launchUrl}>
                        <img src={btnLaunch} className="btn-start" alt={"start"}/>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Play;
