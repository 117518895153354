import React, {useEffect, useState} from 'react';

import './../assets/css/font.css';
import './Ipad.css';

import {AppContext, createStageClass, Sprite} from "react-pixi-fiber";
import * as PIXI from "pixi.js";

import buttonCross from "./../assets/images/button-cross.png";
import buttonHeart from "./../assets/images/button-heart.png";
import buttonHexagon from "./../assets/images/button-hexagon.png"
import buttonOval from "./../assets/images/button-oval.png"
import buttonRectangle from "./../assets/images/button-rectangle.png"
import buttonStar from "./../assets/images/button-star.png"
import buttonTriangle from "./../assets/images/button-triangle.png"
import launchActive from "./../assets/images/launch-active.png";
import logoPresspad from "./../assets/images/logo-presspad.png";
import {createSocket} from "./Socket";

import {useParams} from "react-router-dom"
import {setSize} from "./Utils";

function Presspad(props) {

    const [scaleX, setScaleX] = useState(1)
    const [scaleY, setScaleY] = useState(1)
    const {boardId} = useParams()
    const Stage = createStageClass();

    let appContext
    let currentlyPressed = 0;
    let socket

    const buttons = [
        {x: 150, y: 750, asset: buttonStar},
        {x: 325, y: 650, asset: buttonOval},
        {x: 500, y: 550, asset: buttonCross},
        {x: 675, y: 480, asset: buttonHeart},
        {x: 850, y: 550, asset: buttonHexagon},
        {x: 1025, y: 650, asset: buttonTriangle},
        {x: 1200, y: 750, asset: buttonRectangle},
    ];

    useEffect(() => {

        socket = createSocket()
        setSize(appContext, setScaleX, setScaleY);
        window.addEventListener('resize', () => {
            setSize();
        })
    })

    function onLaunch() {
        if (socket) {
            socket.emit('press', { id: currentlyPressed, board: typeof boardId === 'undefined' ? 0 : boardId })
        }
    }

    function onPress(id) {
        currentlyPressed = id;
    }

    function storeContext(app) {
        appContext = app;
        return
    }

    return (
        <div className="bg-orange step">
            <Stage options={{ backgroundAlpha: 0, width: 1366, height: 1024 }} scale={[scaleX, scaleY]}>
                <AppContext.Consumer>
                    {(app) => <>{storeContext(app)}</>}
                </AppContext.Consumer>

                <Sprite texture={PIXI.Texture.from(logoPresspad)}
                        anchor={0.5}
                        x={680}
                        y={250}
                />

                {buttons.map((elem, index) => {
                    return <Sprite texture={PIXI.Texture.from(elem.asset)}
                                   interactive={true}
                                   buttonMode={true}
                                   pointerdown={() => { onPress(index) }}
                                   anchor={0.5}
                                   x={elem.x}
                                   y={elem.y}
                                   key={index}
                    />
                })}

                <Sprite texture={PIXI.Texture.from(launchActive)}
                        interactive={true}
                        buttonMode={true}
                        pointerdown={onLaunch}
                        anchor={0.5}
                        x={680}
                        y={800}
                />

            </Stage>
        </div>
    );
}

export default Presspad;
