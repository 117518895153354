import Rectangle from "./Rectangle";
import {forwardRef} from "react";

const Hotspot = forwardRef((props, ref) => {
    return <Rectangle  width={80} height={50} fill={0xF00000} fillOpacity={0}
                       ref={ref}
        {...props}
    />;
});

export default Hotspot;
