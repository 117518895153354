import {Link} from "react-router-dom";
import React from 'react';

import './../assets/css/font.css';
import './Step1.css';

import logoSmallGreen from './../assets/images/logo-small-green.png'
import monster from './../assets/images/monster.png'
import btnNext from './../assets/images/btn-next.png'
import btnPlay from './../assets/images/button-play.png'

class StepItem extends React.Component {

    propsExample = {
        cssClassName: 'bg-green',
        logo: logoSmallGreen,
        nextLink: '',
        playLink: '',
        tips: [],
        question: ''
    }

    constructor(props) {
        super(props);
        this.state = {
            showTooltip: false,
            index: -1,
        }
    }

    showTooltip() {
        this.setState({
            showTooltip: true,
            index: this.state.index + 1 >= this.props.tips.length ? 0 : this.state.index + 1
        })
    }

    hideTooltip() {
        this.setState({
            showTooltip: false,
            index: -1
        })
    }

    render() {
        return (
            <div className={`${this.props.cssClassName} step`}>
                <div className="header">
                    <div className={"logo"}>
                        <img src={this.props.logo} alt={"logo"}/>
                    </div>
                </div>

                <div className="content">
                    <div className="question">{this.props.question}</div>
                </div>

                <div className={"monster-container"}>
                    {this.state.showTooltip ?
                        <div className={"tooltip tooltip-1"}>
                            <span>{this.props.tips[this.state.index]}</span>
                        </div>
                        : '' }

                    <img className={"monster"} src={monster} onClick={this.showTooltip.bind(this)} alt={"monster"}/>
                </div>


                {this.props.nextLink ?
                    <div className="button-overlay-center">
                        <Link to={this.props.nextLink} onClick={this.hideTooltip.bind(this)}>
                            <img src={btnNext} className="button-next" alt={"next"} />
                        </Link>
                    </div> : ''}

                {this.props.playLink ?
                    <div className="button-overlay-left">
                        <Link to={this.props.playLink}>
                            <img src={btnPlay} className="button-play" alt={"play"}/>
                        </Link>
                    </div> : ''
                }
            </div>
        );
    }
}

export default StepItem;
