import {useParams} from "react-router-dom";
import React from 'react';

import './../assets/css/font.css';
import './Step1.css';

import logoSmallGreen from './../assets/images/logo-small-green.png'
import logoSmallOrange from './../assets/images/logo-small-orange.png'
import StepItem from "./StepItem";

function Step() {

    const maxSteps = 3;
    const {version, id} = useParams();
    const intVersion = parseInt(version, 10) - 1;
    const intId = parseInt(id, 10) - 1;
    const questions =
        [
            [
                "Kunnen dieren verliefd zijn?",
                "Kunnen Robots lachen?",
                "Wat is het verschil tussen mensen en alle andere diersoorten?"
            ],
            [
                "Waarom bepalen je ouders dingen voor jou?",
                "Waarom worden er op school dingen voor jou bepaald?",
                "Hoe beslis je dan wanneer je wel en wanneer je niet luistert naar andere mensen?"
            ]
        ]

    const tips = [
        [
            [
                "Hoe kan je zien dat een dier verliefd is?",
                "Wat is verliefd zijn?",
                "Hoe weet je het zeker?"
            ],
            [
                "Moet je iets voelen bij lachen, of alleen je tanden laten zien?",
                "Wat is een robots?",
                "Als je een robot programmeert om te lachen, vindt hij dan ook iets grappig?"
            ],
            [
                "Zijn wij de baas over dieren?",
                "Hoe praten we met elkaar?",
                "Dragen dieren ook kleding?"
            ]
        ],
        [
            [
                "Moet je altijd naar je ouders luisteren? Wanneer wel? Wanneer niet?",
                "Mag iedereen die ouder is dan jij dingen bepalen voor jou?",
                "Welke dingen bepaal jij voor je ouders?"
            ],
            [
                "Waarom luister je naar de leerkracht?",
                "Mag je leraar bepalen welke kleren je aan hebt?",
                "Hebben je vrienden ook iets over jou te zeggen?"
            ],
            [
                "Kan iemand een voorbeeld geven wanneer je niet luistert naar andere mensen?",
                "Wanneer luister je niet naar jezelf?",
                "Wat bepaal jij en niemand anders?"
            ]
        ]
    ]

    const options = [
        { bg: 'bg-green', logo: logoSmallGreen },
        { bg: 'bg-orange', logo: logoSmallOrange }
    ]


    const nextLink = intId+2>maxSteps ? null : `/step/${version}/${intId+2}`

    return (
        <StepItem cssClassName={options[intVersion].bg} logo={options[intVersion].logo} question={questions[intVersion][intId]} tips={tips[intVersion][intId]}
                  nextLink={nextLink} playLink={`/play/${version}`} />
    );
}

export default Step;
