import {Sprite, Container, Text} from "react-pixi-fiber";
import * as PIXI from "pixi.js";
import cardActive from "../../assets/images/card-active.png";
import { hitTestRectangle } from './../Utils';
import {createRef} from "react";

function Card({id, hotspots, onTarget, ...props}) {
    let currentTarget;
    const container = createRef();

    function onDragStart(e) {
        currentTarget = e.target;
    }

    function onDragEnd() {
        let hasCollision = false;
        let hasCollisionWithTarget = -1;

        for (let i in hotspots) {
            if (container.current && hotspots[i].ref.current && hitTestRectangle(container.current, hotspots[i].ref.current)) {
                // console.log(`Collision with ${i}`);

                hasCollisionWithTarget = i;

                container.current.x = hotspots[i].x;
                container.current.y = hotspots[i].y;
                container.current.rotation = hotspots[i].r;
                hasCollision = true;
                break;
            }
        }

        currentTarget = null;

        if (onTarget && typeof onTarget === 'function') {
            onTarget(hasCollision, hasCollisionWithTarget)
        }
    }

    function onDragMove(e) {
        if (currentTarget && currentTarget.parent) {
            currentTarget.parent.toLocal(e.data.global, null, currentTarget.position)
        }
    }

    return <Container
        anchor={0.5}
        x={1200}
        y={125}
        scale={1.08}
        interactive={true}
        buttonMode={true}
        pointerup={onDragEnd}
        pointerupoutside={onDragEnd}
        pointerdown={onDragStart}
        pointermove={onDragMove}
        ref={container}>
        <Sprite texture={PIXI.Texture.from(cardActive)}
                anchor={0.5}
        />
        <Text text={props.text?.toUpperCase()}
              anchor={0.5}
              style={{
                  align: "center",
                  wordWrap: true,
                  wordWrapWidth: 280,
                  fontFamily: 'Alphakind Regular',
                  fill: 0xFFFFFF,
                  fontSize: '3.8em',
                  dropShadow: true,
                  dropShadowAlpha: 0.8,
                  dropShadowBlur: 5,
                  dropShadowDistance: 3
              }}/>
    </Container>;
}

export default Card;
