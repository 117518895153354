import {Sprite} from "react-pixi-fiber";
import * as PIXI from "pixi.js";
import launchActive from "../../assets/images/launch-active.png";
import launchInactive from "../../assets/images/launch-inactive.png";
import {Component, createRef} from "react";


class LaunchButton extends Component {

    spriteRef = createRef();
    active = false;

    constructor(props) {
        super(props);

        if (props.onRegister) {
            props.onRegister(this);
        }
    }

    setIsActive(active) {
        this.active = active;
        if (this.active)
            this.spriteRef.current.texture = PIXI.Texture.from(launchActive)
        else
            this.spriteRef.current.texture = PIXI.Texture.from(launchInactive)
    }


    onPress() {
        if (this.active) {
            if (this.props.onLaunch)
                this.props.onLaunch();
        }
    }

    render() {
        return <Sprite texture={PIXI.Texture.from(launchInactive)}
                       interactive={true}
                       buttonMode={true}
                       pointerdown={this.onPress.bind(this)}
                       anchor={0.5}
                       x={this.props.x}
                       y={this.props.y}
                       ref={this.spriteRef}
        />;
    }
}

export default LaunchButton;
